import React from 'react'
import { withRouter } from "../withrouter.js";
//import { withRouter } from 'react-router';
import { nav } from "./getParamsAndNavigate.js";
//import { useNavigate } from 'react-router-dom';
//import { withRouter } from "react-router";
//import logo from './logo.svg';
import PropTypes from 'prop-types';
import { redirect } from "react-router-dom";
import './App.css';





function App(props) 
{

  const createNewGame = (item) => { 

    let url = "api/ScoreBoard/CreateNewGame";
    // let response = 
    
    fetch(url)

    .then(response => response.json())
    .then(response => { 
      console.log(response);
      nav('/operator/' + response);
     })
    .catch(err => console.error(err));


    //nav('/Operator') ;
    // nav('/operator/' + item.id) 
  }

  return (
    <div className="App">
      <header className="App-header">
      
        <table>
          <tbody>
          <tr>
            <td><button onClick={createNewGame} className='CreateNew'>Create New</button></td>
          </tr>
          
          
          </tbody>
        </table>
        
      </header>
    </div>
  );
}
class Appssssss extends React.Component {


  constructor(props) {
    super(props);
    var nav = this.props.navigate;
    console.log(props);
    this.state = {scoreHome: 0,scoreAway : 0};
    this.createNewGame = this.createNewGame.bind(this);
    
    /*
    this.homeDown = this.homeDown.bind(this);
    this.awayUp = this.awayUp.bind(this);
    this.awayDown = this.awayDown.bind(this);
    this.clearScores = this.clearScores.bind(this);
    this.retrieveFromServer = this.retrieveFromServer.bind(this);

    */
  }

  createNewGame()
  {
    console.log(this.props);
    redirect('/operator');
    /*
    this.props.navigate.push('/');

    const{params,navigate} = this.props;
    //const navigate = useNavigate();
    let url = "api/ScoreBoard/CreateNewGame";
    // let response = 
    
    fetch(url)

    .then(response => response.json())
    .then(response => { 
      console.log(response);
      navigate('/operator/{response}');
     })
    .catch(err => console.error(err));
    */
  }

  render() 
  {


    return (
      <div className="App">
        <header className="App-header">
        
          <table>
            <tbody>
            <tr>
              <td><button onClick={this.createNewGame} className='CreateNew'>Create New</button></td>
            </tr>
            
            
            </tbody>
          </table>
          
        </header>
      </div>
    );
  }
}

/*

<tr>
              <td><button onClick={this.homeDown}  className='Button-Down'>-</button></td><td><button onClick={this.awayDown} className='Button-Down'>-</button></td>
            </tr>
            <tr>
              <td colSpan={2}><button onClick={this.retrieveFromServer}  className='Button-Retrieve'>Get from Server</button></td>
            </tr>
*/
export default withRouter(App);


//export default App;