import React from 'react';
import { 
  BrowserRouter,
  Routes,
  Route,
  Link } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import OperatorPage from './OperatorPage/OperatorPage';
import Watcher  from './Watcher/Watcher';
import reportWebVitals from './App/reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter>
 
      <Routes>
    
        <Route path="/Watcher/:uuid" element={< Watcher />} />
        <Route path="/Operator/:uuid" element={< OperatorPage />} />
        <Route exact path="/" element={ <App />} />
      </Routes>
    </BrowserRouter>

);






// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
