import './Watcher.css';
import React, { useEffect, useState } from "react";
import { 
    useParams
  } from "react-router-dom";




  class WatcherComp extends React.Component {


    constructor(props) {
      super(props);
      this.Home = 0;
      this.Away = 0;
      this.state = {scoreHome: 0,scoreAway : 0};
      //this.homeUp = this.homeUp.bind(this);
      //this.homeDown = this.homeDown.bind(this);
      //this.awayUp = this.awayUp.bind(this);
      //this.awayDown = this.awayDown.bind(this);
      //this.clearScores = this.clearScores.bind(this);
      this.retrieveFromServer = this.retrieveFromServer.bind(this);
      //this.updateScore = this.updateScore.bind(this);
      this.uuid = props.uuid;
      //console.log("UUID = "+ this.uuid);
      this.retrieveFromServer();

      
      setInterval(this.retrieveFromServer, 3000);
    }
  /*
    homeUp()
    {
      this.Home = this.state.scoreHome + 1;
      this.setState(prevState => ({ scoreHome: this.Home }));
      
      this.updateScore();
    }
  
  
    updateScore()
    {
      console.log("Home = " + this.Home + ", Away = " + this.Away);
      let requestOptions = { 
        method: 'PUT', 
        // headers: { ...authHeader(), 'Content-Type': 'application/json' },
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({Home: this.Home, Away: this.Away, GameID: this.uuid})
      };
  
      return fetch(`/api/ScoreBoard/scoreupdate`, requestOptions);
    }
  
    homeDown()
    {
      if (this.state.scoreHome > 0){
        this.Home = this.state.scoreHome - 1;
        this.setState(prevState => ({ scoreHome: this.Home }));
        
        this.updateScore();
      }
    }
  
    awayUp()
    {
      this.Away = this.state.scoreAway + 1;
      this.setState(prevState => ({ scoreAway: this.Away }));
      
      this.updateScore();
    }
    awayDown()
    {
      if (this.state.scoreAway > 0)
      {
        this.Away = this.state.scoreAway - 1;
        this.setState(prevState => ({ scoreAway: this.Away }));
        this.updateScore();
      }
    }
  
  */
    retrieveFromServer()
    {
      //let url = "http://localhost:5215/api/ScoreBoard/";
      let url = "/api/ScoreBoard/" + this.uuid;
      // let response = 
      
      console.log("url = " + url);
  
  
      fetch(url)
  
      .then(response => response.json())
      .then(response => { 
        console.log(response);
        this.Away = response.away;
        this.Home = response.home;
        this.setState(prevState => ({ scoreAway: response.away }));
        this.setState(prevState => ({ scoreHome: response.home }));
       })
      .catch(err => console.error(err));


    }
  
  /*
      if (response.ok) { // if HTTP-status is 200-299
        // get the response body (the method explained below)
        let json =  response.json();
      } else {
        alert("HTTP-Error: " + response.status);
      }
  
  
    
    clearScores()
    { 
      this.Away = 0;
      this.Home = 0;
      this.setState(prevState => ({ scoreAway: 0, scoreHome : 0 }));
    }
  */
    render() 
    {
  
  
      return (
        <div className="WatcherComp">
          <header className="WatcherComp-header">
          
            <table>
              <tbody>

              <tr>
                <td>Home</td><td>Away</td>
              </tr>
              <tr>
                <td className="WatcherComp-Home">{this.state.scoreHome}</td><td className="WatcherComp-Away">{this.state.scoreAway}</td>
              </tr>
            
              <tr>
                <td colSpan={2}><button onClick={this.retrieveFromServer}  className='Button-Retrieve'>Get from Server</button></td>
              </tr></tbody>
            </table>
            
          </header>
        </div>
      );
    }
  }
  
  









/*

function retrieveFromServer(uuid)
{
  let url = "/api/ScoreBoard/" + uuid;
  //let result = uuid; 
  
  //console.log("url = " + url);

  

  return fetch(url)
    .then(handleResponse);
  

}


function handleResponse(response) {
    return response.text().then(text => {
        console.log("text = " + text);
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                //logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}


*/

function Watcher(props) 
{
 //   console.log(useParams());
 //   const [state, setState] = useState([])
  let { uuid } = useParams();
  // console.log("uuid = " + uuid);

  // state.away = 10;
  // state.home = 0;
/*
  retrieveFromServer(uuid)
    .then(iets => 
    {
        console.log("retrieveFromServer = " + iets);
        home = iets.home;
        away = iets.away;
    });
  

    let url = "/api/ScoreBoard/" + uuid;
    fetch(url)
        .then(response => response.json())
        .then(iets => {
            console.log("iets = "+ iets);
            console.log("home = "+ state.home);
            state.home = 1; // iets.home;
            console.log("home = "+ state.home);
            state.away = iets.away;
           console.log("away = "+ state.away);
        })
        .catch(err => console.error(err));


    console.log("After the fetch");
*/

  return (
    <div className="Watcher">
        <header className="Watcher-header">
        
          <WatcherComp uuid={uuid} />
          
        </header>
      </div>
  );
}


export default Watcher