import React from 'react'
import { withRouter } from "../withrouter.js";
//import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import './Operator.css';



class Operator extends React.Component {


  constructor(props) {
    super(props);
    this.Home = 0;
    this.Away = 0;
    this.state = {scoreHome: 0,scoreAway : 0};
    this.homeUp = this.homeUp.bind(this);
    this.homeDown = this.homeDown.bind(this);
    this.awayUp = this.awayUp.bind(this);
    this.awayDown = this.awayDown.bind(this);
    this.clearScores = this.clearScores.bind(this);
    this.retrieveFromServer = this.retrieveFromServer.bind(this);
    this.copyTheURL = this.copyTheURL.bind(this);
    this.updateScore = this.updateScore.bind(this);
    this.uuid = props.uuid;
    console.log("UUID = "+ this.uuid);
    console.log("Test2 = "+ this.uuid);
    this.retrieveFromServer();
    console.log("After retrieve from Server");
  }

  homeUp()
  {
    this.Home = this.state.scoreHome + 1;
    this.setState(prevState => ({ scoreHome: this.Home }));
    
    this.updateScore();
  }


  updateScore()
  {
    console.log("Home = " + this.Home + ", Away = " + this.Away);
    let requestOptions = { 
      method: 'PUT', 
      // headers: { ...authHeader(), 'Content-Type': 'application/json' },
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({Home: this.Home, Away: this.Away, GameID: this.uuid})
    };

    return fetch(`/api/ScoreBoard/scoreupdate`, requestOptions);
  }

  homeDown()
  {
    if (this.state.scoreHome > 0){
      this.Home = this.state.scoreHome - 1;
      this.setState(prevState => ({ scoreHome: this.Home }));
      
      this.updateScore();
    }
  }

  awayUp()
  {
    this.Away = this.state.scoreAway + 1;
    this.setState(prevState => ({ scoreAway: this.Away }));
    
    this.updateScore();
  }
  awayDown()
  {
    if (this.state.scoreAway > 0)
    {
      this.Away = this.state.scoreAway - 1;
      this.setState(prevState => ({ scoreAway: this.Away }));
      this.updateScore();
    }
  }


  retrieveFromServer()
  {
    
    let url = "/api/ScoreBoard/" + this.uuid;
    // let response = 
    
    console.log("url = " + url);
    console.log("full = " + window.location.href);


    fetch(url)

    .then(response => response.json())
    .then(response => { 
      console.log(response);
      this.Away = response.away;
      this.Home = response.home;
      this.setState(prevState => ({ scoreAway: response.away }));
      this.setState(prevState => ({ scoreHome: response.home }));
     })
    .catch(err => console.error(err));
  


  }



  clearScores()
  { 
    this.Away = 0;
    this.Home = 0;
    this.setState(prevState => ({ scoreAway: 0, scoreHome : 0 }));
  }


  cutOffIncludingLastSlash(inputString)
  {
    var lastSlashFound = inputString.lastIndexOf('/');
    if (lastSlashFound < 1)
      return inputString;
    return inputString.substr(0, lastSlashFound);
  }

  copyTheURL()
  { 
  /*
   let url = "/api/ScoreBoard/" + this.uuid;
    
    
    console.log("url = " + url);
    */
    console.log("full = " + window.location.href);

    console.log("full - 1 iter = " + this.cutOffIncludingLastSlash(window.location.href));
    console.log("full - 2 iter = " + this.cutOffIncludingLastSlash(this.cutOffIncludingLastSlash(window.location.href)));

    let urlToCopy = this.cutOffIncludingLastSlash(this.cutOffIncludingLastSlash(window.location.href)) + "/Watcher/" + this.uuid;


    // window.open(urlToCopy, "_blank");
    navigator.clipboard.writeText(urlToCopy);  

// <td colSpan={2}><button onClick={this.openWatcherURL} className='Button-OpenWatcherURL'>Open Watcher</button></td>
   
// <td colSpan={2}><button onClick={this.copyTheURL} className='Button-CopyViewerURL'>Copy viewer URL</button></td>

  }



  render() 
  {


    return (
      <div className="Operator">
        <header className="Operator-header">
        
          <table>
            <tbody>
            <tr>
              <td colSpan={2}><button onClick={this.clearScores} className='Button-ClearScores'>Clear Scores</button></td>
            </tr>
            <tr>
            <td colSpan={2}><button onClick={this.copyTheURL} className='Button-CopyViewerURL'>Copy viewer URL</button></td>
              
              
            </tr>
            <tr>
              <td><button onClick={this.homeUp} className='Button-Up'>+</button></td><td><button onClick={this.awayUp} className='Button-Up'>+</button></td>
            </tr>
            <tr>
              <td>Home</td><td>Away</td>
            </tr>
            <tr>
              <td>{this.state.scoreHome}</td><td>{this.state.scoreAway}</td>
            </tr>
            <tr>
              <td><button onClick={this.homeDown}  className='Button-Down'>-</button></td><td><button onClick={this.awayDown} className='Button-Down'>-</button></td>
            </tr>
            <tr>
              <td colSpan={2}><button onClick={this.retrieveFromServer}  className='Button-Retrieve'>Get from Server</button></td>
            </tr></tbody>
          </table>
          
        </header>
      </div>
    );
  }
}


// export default withRouter(Operator);
export default Operator;

