import Operator from "../Operator/Operator.js";
import { 
    useParams
  } from "react-router-dom";

function OperatorPage(props) 
{
    console.log(useParams());
  let { uuid } = useParams();
  console.log("uuid = " + uuid);

  return (
    <Operator uuid={uuid} />
  );
}


//export default withRouter(OperatorPage)
export default OperatorPage
