import { createBrowserHistory } from 'history';

import { useParams, useNavigate } from "react-router-dom";

    export function withParamsAndNavigate(Component) {
      return (props) => (
       <Component {...props} params={useParams()} navigate={useNavigate()} />
  );
}

const history = createBrowserHistory();

// For navigating programatically (e.g. in an onClick callback as opposed to clicking on a link)
// by pushing a URL onto the history in the store
// NOTE: must be in store.jsx to prevent cyclical dependency error
export const nav = (url) => {
    history.push(url);
    window.location.href = window.location.href;
}  